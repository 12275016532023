import React, { Component } from 'react'
import { Header } from 'semantic-ui-react'
import { Link } from 'gatsby'
import SocialShareButtons from '../SocialShareButtons'

import EmailForm from '../EmailForm'

const url = 'oclo.io';
const descriptionMessage = 'Get%20Inspired:%20How%20to%20cook%20with%20the%20seasons.';

const Footer = () => {
  return (
    <div className='footer-container'>
      <div className='footer-content-container'>
        <Header as='h5'>Stay up to date!</Header>
        <p>
          We're working on a lot more to help you easily find and use local,
          sustainably produced food that's nourishing for you and the
          environment.
        </p>
        <EmailForm />
        <Link to='/about-us'>
          <Header sub className='san-serif'>ABOUT US</Header>
        </Link>
      </div>
      <div className='footer-bar'>
        <div className='copyright'>OCLO</div>
        <SocialShareButtons url={url} descriptionMessage={descriptionMessage} />
      </div>
    </div>
  )
}


export default Footer
