import React from 'react'
import { Icon } from 'semantic-ui-react'

const handleShare = (type, url) => {
  // window.analytics && window.analytics.track("Oclo Share", { type })
  const windowName = type === 'email' ? '_self' : '_blank'
  window.open(url, windowName);
}

const SocialShareButtons = (props) => {
  const { url, descriptionMessage } = props
  return (
    <div className='social-button-container'>
      <Icon
        size='large'
        link
        name='facebook'
        onClick={() => handleShare('facebook', `https://facebook.com/sharer/sharer.php?u=${url}?ref=facebook-share`)}
      />
      <Icon
        size='large'
        link
        name='twitter'
        onClick={() => handleShare('twitter', `https://twitter.com/intent/tweet/?text=${descriptionMessage}&amp;url=${url}?ref=twitter-share`)}
      />
      <Icon
        size='large'
        link
        name='pinterest'
        onClick={() => handleShare('pinterest', `https://www.pinterest.com/pin/create/button/?url=${url}`)}
        data-pin-do="buttonBookmark"
      />
      <Icon
        size='large'
        link
        name='mail'
        onClick={() => handleShare('email', `mailto:?subject=${descriptionMessage}&amp;body=${url}?ref=email-share`)}
      />
    </div>
  )
}

export default SocialShareButtons
