import React, { Component } from 'react'
import { Button, Form, Input, Message } from 'semantic-ui-react'
import { Link } from 'gatsby'
import ReactHTMLParser from 'react-html-parser'
import addToMailchimp from '../lib/mailchimp.js'


class EmailForm extends Component {
  state = { email: '', errorMessage: null, successMessage: null }

  handleEmailChange = (e, { value }) => this.setState({ email: value })

  handleSubmit = e => {
    const { email } = this.state
    console.log(email)
    e.preventDefault();
    addToMailchimp(email)
      .then(data => {
        console.log(data)
        if (data.result === 'error') {
          this.setState({
            errorMessage: data.msg,
            successMessage: null
          })
        } else {
          this.setState({
            email: '',
            errorMessage: null,
            successMessage: data.msg
          })
        }
      })
  }

  formatErrMsg = (errorMessage) => {
    const htmlIndex = errorMessage && errorMessage.indexOf('<')
    const errMsg = errorMessage.slice(0, htmlIndex)
    const htmlMsg = errorMessage.slice(htmlIndex)
    return ReactHTMLParser(errMsg.concat('<br />', htmlMsg))
  }

  render() {
    const { email, errorMessage, successMessage } = this.state

    return (
      <Form error success onSubmit={this.handleSubmit}>
        <Form.Group>
          <Form.Field>
            <Input
              className='basic'
              name='email'
              value={email}
              placeholder='email address'
              onChange={this.handleEmailChange}
            />
          </Form.Field>
          <Button type='submit' className='primary normal'>subscribe</Button>
        </Form.Group>
        { errorMessage &&
            <Message error>
              { this.formatErrMsg(errorMessage) }
            </Message>
        }
        { successMessage && <Message success content={successMessage} /> }
      </Form>
    )
  }
}


export default EmailForm
