import React from 'react'
import { Link } from 'gatsby'
import { Menu } from 'semantic-ui-react'

const Header = (props) => {
  // const { siteTitle } = props
  return (
    <Menu secondary>
      <Menu.Item as={Link} to="/">
        Oclo
      </Menu.Item>
      <Menu.Menu position="right">
        <Menu.Item as={Link} activeClassName="active" to="/">
          Home
        </Menu.Item>
        {/* <Menu.Item as={Link} activeClassName="active" to="/producers">
          Producers
        </Menu.Item> */}
        <Menu.Item as={Link} activeClassName="active" to="/about-us">
          About Us
        </Menu.Item>
        <Menu.Item as={Link} activeClassName="active" to="/food-principles">
          Food Principles
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  )
}

export default Header
