import jsonp from 'jsonp'
import { validate } from 'email-validator'

const __GATSBY_PLUGIN_MAILCHIMP_ADDRESS__ = 'https://Oclo.us19.list-manage.com/subscribe/post?u=f542b0fec1cd357fee5b0e3eb&amp;id=e9b5d0a248'

/*
 * make a jsonp request to user's mailchimp list
 * url is a concatenated string of user's gatsby-config.js
 * options, along with any MC list fields as query params
 */

const subscribeEmailToMailchimp = url => (
  new Promise((resolve, reject) => {
    // `param` object avoids CORS issues
    return jsonp(url, { param: 'c' }, (err, data) => {
      if (err) reject(err)
      if (data) resolve(data)
    })
  })
)

/*
 * build a query string of MC list fields
 * ex: '&KEY1=value1&KEY2=value2'
 * (toUpperCase because that's what MC requires)
 */

const convertListFields = fields => {
  let queryParams = ''
  for (const field in fields) {
    queryParams = queryParams.concat(`&${field.toUpperCase()}=${fields[field]}`)
  }
  return queryParams
}

/*
 * accept email (String) and additional, optional
 * Mailchimp list fields (Object)
 * then make jsonp req with data
 */

const addToMailchimp = (email, fields) => {
  const isEmailValid = validate(email)
  const emailEncoded = encodeURIComponent(email)
  if (!isEmailValid) {
    return Promise.resolve({
      result: 'error',
      msg: 'The email you entered is not valid.'
    })
  }

  // generate Mailchimp endpoint for jsonp request
  // note, we change `/post` to `/post-json`
  // otherwise, Mailchomp returns an error
  const endpoint = __GATSBY_PLUGIN_MAILCHIMP_ADDRESS__.replace(/\/post/g, '/post-json')

  const queryParams = `&EMAIL=${emailEncoded}${convertListFields(fields)}`
  const url = `${endpoint}${queryParams}`
  return subscribeEmailToMailchimp(url)
}

export default addToMailchimp
