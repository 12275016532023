import React from 'react'
import './index.css'
import '../../semantic/dist/semantic.min.css'

import Header from './Header'
import Footer from './Footer'

const Layout = ({ children }) => {
  return (
    <div id="root">
      <Header />
      {children}
      <Footer />
    </div>
  )
}

export default Layout
